<template>
  <nav class="navbar-subnavigation" aria-label="breadcrumb">
    <ol class="breadcrumb">
      <template v-for="(item, index) in items">
        <li
          v-if="index === items.length - 1"
          class="breadcrumb-item active"
          aria-current="page"
        >
          {{ item.text }}
        </li>
        <li v-else class="breadcrumb-item">
          <a :href="item.path || ''" :title="item.title || item.text || ''">{{
            item.text
          }}</a>
        </li>
      </template>
    </ol>
  </nav>
</template>

<script>
function parseItems(route) {
  if (!route.meta.breadcrumb) {
    return [];
  }

  return route.meta.breadcrumb.map(function (item) {
    if (__.isString(item)) {
      //Si el string empieza con ":", parseo el resto y miro si es un parámetro de la ruta
      const param = item.match(/^:(.+)$/)[1];
      if (param && route.params[param]) {
        return { param: param, text: route.params[param] };
      }
      return null;
    }

    return item;
  });
}

export default {
  name: "Breadcrumb",

  data() {
    return {
      items: [],
    };
  },

  mounted() {
    this.items = parseItems(this.$route);

    window.addEventListener("scroll", this.onScroll);

    this.$bus.$on("breadcrumb:update", this.update);
  },

  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll);

    this.$bus.$off("breadcrumb:update", this.update);
  },

  methods: {
    //Actualizo uno o más ítems del breadcrumb, identificados por el campo "param"
    update(params) {
      for (let p in params) {
        const itemIndex = this.items.findIndex((item) => item.param == p);

        if (itemIndex >= 0) {
          //Importante usar Object.assign para conservar la reactividad y la clave (el campo "param")
          Object.assign(this.items[itemIndex], params[p]);
        }
      }
    },

    onScroll() {
      var breadcrumb = document.querySelector(".navbar-subnavigation");

      var scroll = window.scrollY;

      if (scroll >= 30) {
        breadcrumb.classList.add("scrolled");
      } else {
        breadcrumb.classList.remove("scrolled");
      }
    },
  },

  watch: {
    $route() {
      this.items = parseItems(this.$route);
    },
  },
};
</script>
